<template>
    <v-container class="pt-5 px-0" fluid>
        <v-container class="content" fluid>
            <v-row no-gutters>
                <v-col md="8" class="pr-10">
                    <Headline text="neuigkeiten" />

                    <v-container v-for="(newsEntry, i) in $db.news.top" :key="i" fluid>
                        <v-row >
                            <v-col sm="4">
                                <axz-img :id="newsEntry.image" dummy="news_dummy.png" />
                            </v-col>
                            <v-col sm="8">
                                <v-row no-gutters>
                                    <span class="text-body-1 primary--text">axXez</span>
                                </v-row>
                                <v-row no-gutters>
                                    <span class="text-h6 text-uppercase">{{newsEntry.title}}</span>
                                </v-row>
                                <v-row no-gutters class="py-2">
                                    <span class="text-body-2 grey--text"><v-icon small left color="accent">far fa-calendar-alt</v-icon> {{UTCtoLocal(newsEntry.published)}}</span>
                                    <span class="text-body-2 ml-4 grey--text"><v-icon small left color="accent">fas fa-user</v-icon> geschrieben von <span class="accent--text">{{newsEntry.writer}}</span></span>
                                </v-row>
                                <v-row no-gutters class="mt-2">
                                    <span class="text-body-1 accent--text">{{newsEntry.short}}</span>
                                </v-row>
                                <v-row no-gutters class="mt-8">
                                    <p class="text-body-2" style="overflow: hidden !important; text-overflow: ellipsis !important; white-space: break-spaces;">{{newsEntry.text.slice(0, 260)}}...</p>
                                    <!--p v-for="(row, i) in newsEntry.text.split('\n')" :key="i" class="text-body-2">{{row.trim()}}</p-->
                                </v-row>
                                <v-btn text color="accent" :to="{ name: 'newsitem', params: { news_id: newsEntry.ID } }">Weiterlesen</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-divider />
                        </v-row>
                    </v-container>
                    
                </v-col>
                <v-col md="4">
                    <Headline text="letzte themen" />
                    <v-list-item v-for="(newsEntry, i) in $db.news.latest" :key="i" link :to="{ name: 'newsitem', params: { news_id: newsEntry.ID } }">
                        <v-list-item-icon>
                            <v-icon color="accent">fas fa-folder</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6 text-uppercase">{{newsEntry.title}}</v-list-item-title>
                            <v-list-item-subtitle>geposted am {{UTCtoLocal(newsEntry.published)}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <Headline text="letzte tweets" class="pt-6" />
                    <v-row v-for="tweet in $db.news.latestTweets" :key="tweet.id">
                        <Tweet :tweet="tweet" />
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    export default {
        mounted () {
            this.$db.news.loadTop();
            this.$db.news.loadLatest();
            this.$db.news.loadLatestTweets();
        }
    }
</script>

<style scoped>

</style>